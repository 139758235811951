import { z } from "zod";

import { paginationQueryBaseSchema } from "./schemas/dataTableSchema";
import { SatisfactionScore } from "./schemas/satisfactionScore";
import { Paginated } from "./types/Paginated";
import { APIResponse } from "./api";
import { AttributeField } from "./attributeFilter";
import {
  EnvironmentSelectionQuerySchema,
  EnvironmentSelectionQueryType,
} from "./environmentAPI";
import {
  FrequencyNumber,
  FunnelStep,
  SortType,
  sortTypeSchema,
} from "./featureAPI";
import { UIFilterSchema } from "./filter";
import { UserDTO } from "./userAPI";

export type CompanyFeature = {
  id: string;
  parentFeatureId: string | null;
  version: string;
  name: string;
  source: "event" | "attribute";
  createdAt: string;
  funnelStep: FunnelStep;
  firstUsed: string | null;
  lastUsed: string | null;
  satisfaction: SatisfactionScore;
  feedbackCount: number;
  frequency: FrequencyNumber;
  usage: { date: string; eventCount: number }[];
  eventCount: number;

  evaluationResult: boolean;

  firstPositiveEvalTime: string | null;
  lastPositiveEvalTime: string | null;
  firstNegativeEvalTime: string | null;
  lastNegativeEvalTime: string | null;
  firstPositiveCheckTime: string | null;
  lastCheckTime: string | null;
  lastCheckResult: boolean | null;
};

export type FeatureMetric = {
  funnelStep: FunnelStep | null;
  eventCount: number;
  firstUsed: string | null;
  lastUsed: string | null;
  frequency: FrequencyNumber;
  satisfaction: SatisfactionScore;
};

export type CompanyListItem = {
  name: string | null;
  id: string;
  firstSeen: string | null;
  lastSeen: string | null;
  userCount: number;
  attributes: Record<string, string>;
  featureMetrics: Record<string, FeatureMetric>;
};

export type AttributeType = "string" | "number" | "boolean" | "date";
export type CompanyList = Paginated<
  CompanyListItem,
  CompaniesQuerySortByType,
  { totalUserCount: number; attributeTypes: Record<string, AttributeType> }
>;

export type CompanyDetail = Omit<CompanyListItem, "userCount">; // TODO: support for attribute types
export type CompanyUserDTO = UserDTO & {
  firstSeen: string | null;
  lastSeen: string | null;
  eventCount: number;
};

export type FeatureCompanyUserDTO = UserDTO & {
  eventCount: number;
  firstUsed: string | null;
  lastUsed: string | null;
};

export const CompanyAttributesLiteral = z.custom<`attributes.${string}`>(
  (val) => {
    return (
      typeof val === "string" &&
      val.length <= 255 && // limit as a sanity check to prevent potential abuse
      val.startsWith("attributes.")
    );
  },
);

export const CompanyFeatureMetricLiteral = <K extends string>(key: K) =>
  z.custom<`featureMetrics.${string}.${K}`>((val) => {
    return (
      typeof val === "string" &&
      val.length <= 255 && // limit as a sanity check to prevent potential abuse
      RegExp(`^featureMetrics\\.\\w+\\.${key}$`).test(val)
    );
  });

export const CompaniesQuerySortBySchema = z
  .enum(["name", "firstSeen", "lastSeen", "userCount"])
  .or(CompanyAttributesLiteral)
  .or(CompanyFeatureMetricLiteral("funnelStep"))
  .or(CompanyFeatureMetricLiteral("satisfaction"))
  .or(CompanyFeatureMetricLiteral("firstUsed"))
  .or(CompanyFeatureMetricLiteral("lastUsed"))
  .or(CompanyFeatureMetricLiteral("frequency"))
  .or(CompanyFeatureMetricLiteral("eventCount"));

export type CompaniesQuerySortByType = z.infer<
  typeof CompaniesQuerySortBySchema
>;

export const CompaniesQuerySchema = EnvironmentSelectionQuerySchema.extend({
  sortBy: CompaniesQuerySortBySchema.default("name"),
  // TODO: looks unused, remove?
  segment: z.string().default("all").optional(),
  companyFilter: UIFilterSchema.optional(),
  idNameFilter: z.string().optional(),
  includeDetailsForFeatureIds: z.array(z.string()).optional(),
})
  .merge(paginationQueryBaseSchema)
  .strict();

export type CompaniesQueryType = z.infer<typeof CompaniesQuerySchema>;

export const CompanyFeaturesQuerySortBySchema = z.enum([
  "name",
  "createdAt",
  "satisfaction",
  "feedbackCount",
  "funnelStep",
  "firstUsed",
  "lastUsed",
  "frequency",
  "eventCount",

  "evaluationResult",
  "lastCheckTime",
  "lastCheckResult",
  "firstPositiveEvalTime",
]);

export type CompanyFeaturesQuerySortByType = z.infer<
  typeof CompanyFeaturesQuerySortBySchema
>;

export const CompanyFeaturesQuerySchema = z
  .object({
    sortOrder: z.enum(["asc", "desc"]).optional().default("asc"),
    sortBy: CompanyFeaturesQuerySortBySchema.optional().default("name"),
    sortType: sortTypeSchema.default("flat").optional(),
  })
  .merge(EnvironmentSelectionQuerySchema)
  .strict();

export type CompanyFeaturesQueryType = z.input<
  typeof CompanyFeaturesQuerySchema
>;

export type CompanyFeaturesTableQueryType = z.output<
  typeof CompanyFeaturesQuerySchema
>;

export const AttributeValuesQuerySchema = z.object({
  filter: z.string().default(""),
  limit: z.preprocess(
    (a) => parseInt(z.string().default("10").parse(a), 10),
    z.number().max(20),
  ),
});

export const CompanyUsersQuerySortBySchema = z.enum([
  "name",
  "email",
  "eventCount",
  "firstSeen",
  "lastSeen",
]);

export type CompanyUsersQuerySortByType = z.infer<
  typeof CompanyUsersQuerySortBySchema
>;

export const CompanyUsersQuerySchema = EnvironmentSelectionQuerySchema.extend({
  sortBy: CompanyUsersQuerySortBySchema.default("eventCount"),
  idNameFilter: z.string().optional(),
})
  .merge(paginationQueryBaseSchema)
  .strict();

export type CompanyUsersQueryType = z.input<typeof CompanyUsersQuerySchema>;

export const FeatureUsersQuerySortBySchema = z.enum([
  "name",
  "email",
  "eventCount",
  "firstUsed",
  "lastUsed",
]);

export type FeatureUsersQuerySortByType = z.infer<
  typeof FeatureUsersQuerySortBySchema
>;

export const FeatureUsersQuerySchema = EnvironmentSelectionQuerySchema.extend({
  sortBy: FeatureUsersQuerySortBySchema.default("name"),
})
  .merge(paginationQueryBaseSchema)
  .strict();

export type FeatureUsersQueryType = z.input<typeof FeatureUsersQuerySchema>;

export const CompanyAttributeValuesQuerySchema =
  EnvironmentSelectionQuerySchema.merge(AttributeValuesQuerySchema);

export type CompanyAttributeValuesQueryType = z.infer<
  typeof CompanyAttributeValuesQuerySchema
>;

export interface CompanyAPI {
  "/apps/:appId/companies/:companyId": {
    GET: {
      response: APIResponse<{
        company: CompanyDetail;
      }>;
      params: { appId: string; companyId: string };
      query: EnvironmentSelectionQueryType;
    };
  };
  "/apps/:appId/companies/:companyId/features": {
    GET: {
      response: Paginated<
        CompanyFeature,
        CompanyFeaturesQuerySortByType,
        { sortType: SortType }
      >;
      params: { appId: string; companyId: string };
      query: CompanyFeaturesQueryType;
    };
  };
  "/apps/:appId/companies/:companyId/users": {
    GET: {
      response: Paginated<CompanyUserDTO, CompanyUsersQuerySortByType>;
      params: { appId: string; companyId: string };
      query: CompanyUsersQueryType;
    };
  };
  "/apps/:appId/companies/:companyId/users/by-feature/:featureId": {
    GET: {
      response: Paginated<FeatureCompanyUserDTO, FeatureUsersQuerySortByType>;
      params: { appId: string; companyId: string; featureId: string };
      query: FeatureUsersQueryType;
    };
  };
  "/apps/:appId/companies": {
    GET: {
      response: APIResponse<CompanyList>;
      params: { appId: string };
      query: CompaniesQueryType;
    };
  };
  "/apps/:appId/company-attributes": {
    GET: {
      response: APIResponse<{
        attributes: AttributeField[];
      }>;
      params: { appId: string };
      query: EnvironmentSelectionQueryType;
    };
  };
  "/apps/:appId/company-attributes/:attribute/values": {
    GET: {
      response: APIResponse<{
        values: string[];
      }>;
      params: { appId: string; attribute: string };
      query: CompanyAttributeValuesQueryType;
    };
  };
}
