import { z } from "zod";

export type SatisfactionScore = 0 | 1 | 2 | 3 | 4 | 5;

export const SatisfactionScoreFilterSchema = z.coerce
  .number()
  .int()
  .gte(0)
  .lte(5)
  .array();

const SatisfactionSpanList = [
  "satisfied",
  "neutral",
  "unsatisfied",
  "none",
] as const;

export type SatisfactionSpan = (typeof SatisfactionSpanList)[number];
